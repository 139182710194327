import '../css/elements.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect, useState, useContext, useMemo} from "react";
import {Page404} from "./PageCodeState";
import {Accordion, Modal, AccordionContext, useAccordionButton, Card} from "react-bootstrap";
import {ScrollRestoration, useParams} from "react-router-dom";
import authFetch from "./auth/authFetch";
import Fuse from "fuse.js";
import {PreloaderMenu} from "./Preloaders";


export function IconElement({image, isElement}) {
    return (
        <div className={`icon-content-block-icon${isElement ? '': ' menu'}`}>
            <img className={`icon-content-block-icon-background${isElement ? '': ' menu'}`}
                 src={image} alt=""/>
            <img className={`icon-content-block-icon-img${isElement ? '': ' menu'}`}
                 src={image} alt=""/>
        </div>
    );
}

export function CardElement({image, title, subtitle, path, isElement, id}) {
    return (
        <div className="card-menu" onClick={() => window.location.assign(path)} id={id}>
            <div className="card-menu-content">
                <div className="card-menu-content-block">
                    <IconElement image={image} isElement={isElement}/>
                    <div className="card-menu-content-block-text">
                        <div className="card-menu-content-block-text-title">{title}</div>
                        <div className="card-menu-content-block-text-subtitle">{subtitle}</div>
                    </div>
                </div>
                <div className="card-menu-content-drill">
                    <img src="/src/images/icons/arrow.svg" alt=""/>
                </div>
            </div>
        </div>
    );
}


const progressBarStyle= {
    animationDuration: "10s"
};

export function ProgressBar() {
    return (<div style={progressBarStyle} className="progress"></div>);
}

export function TagMenu({listOfTags}){
    const [value, setValue] = useState(listOfTags);

    const [activeItem, setActiveItem] = useState(null);

    function checkChange (id){
        setValue(value.map((item) => {
            item.isActive = value.indexOf(item) === id;
            return item;
        }));
        value.map((item) => {
            value.indexOf(item) === id && setActiveItem(value[id]);
        });
    }

    useEffect(() => {
        setValue(listOfTags);
        listOfTags.map((item) => {
            item.isActive && setActiveItem(item);
        });
    }, [listOfTags]);

    return(
        <>
            <div className="tag-menu-container">
                {value.map(item => <div className={`tag-menu-element${item.isActive ? " tag-active" : ""}`}
                                        onClick={() => checkChange(value.indexOf(item))}>{item.title}</div>)}
            </div>
            {activeItem ? <CardMenuElement activeItem={activeItem}/> : <PreloaderMenu/>}
        </>
    );
}


function CardMenuElement({activeItem}){
    const [itemList, setItemList] = useState(null);
    const [fetchedOnce, setFetchedOnce] = useState(false);
    useMemo(()=>{
        if (activeItem){
            let url;
            url = `/api/${activeItem.queryPath}?${(new URLSearchParams(activeItem.filter)).toString()}`;
            authFetch(url).then(res=>
                res.json()).then(data => {
                data.result.length > 0 ? setItemList(data.result) : setItemList(null);
                setFetchedOnce(true);
            });
        }
    }, [activeItem]);
    useEffect(() => {
        if (fetchedOnce){
            const elem= document.getElementById(window.location.hash.replace('#',''));
            if (elem){
                elem.scrollIntoView()
            }
        }
    }, [fetchedOnce]);
    return(
        <>
            <div className="card-menu-container">
                {itemList ? itemList.map((item) => <CardElement
                    image={item.photo_url ? item.photo_url : '/src/icons/profile.png'} isElement={activeItem.isElement}
                    title={item.title} subtitle={item.subtitle} path={`/${item.type}?id=${item.id}`} id={item.id}/>) : <PreloaderMenu/>}
            </div>
            <ScrollRestoration/>
        </>
    )
}

export function PhotoElement({image, button}) {
    return (
        <div className="photo-container">
            {image ? <img className="photo-container-background" src={image} alt=''/> :
                <img className="photo-container-background" src='/src/icons/profile_512.png' alt=''/>}
            {image ? <img className="photo-container-image" src={image} alt=''/> :
                <img className="photo-container-image" src='/src/icons/profile_512.png' alt=''/>}
            {button && <div className="photo-container-button" onClick={() => window.location.assign(button)}>
                <img className="photo-container-button-star" src='/src/images/icons/star.svg' alt=''/>
                <div className="photo-container-button-text">Достижения</div>
            </div>}
        </div>
    )
}

export function PhotoElementSection({image}) {
    return (
        <div className="photo-container section">
            {image ? <img className="photo-container-background" src={image} alt=''/> :
                <img className="photo-container-background" src='/src/icons/logo512.png' alt=''/>}
            {image ? <img className="photo-container-image" src={image} alt=''/> :
                <img className="photo-container-image" src='/src/icons/logo512.png' alt=''/>}
        </div>
    )
}

export function VideoElement({video}) {
    return (
        <div className="photo-container video-container">
            {video ? <iframe className="video-container-video" src={video} allow="autoplay" scrolling="no"></iframe> :
                <img className="photo-container-image" src='/src/icons/logo512.png' alt=''/>}
        </div>
    )
}

export function Title({title}){
    return(
        <div className="title-container">
            <p className="title-text">{title}</p>
        </div>
    );
}

export function Subtitle({subtitle}){
    return(
        <div className="subtitle-container">
            <p className="subtitle-text">{subtitle}</p>
        </div>
    );
}


export function PostInfo({title, subtitle}){
    return(
        <div className="post-info-container">
            <div className="post-info-title-container">{title}</div>
            <div className="post-info-subtitle-container">{subtitle}</div>
        </div>
    )
}

export function ToolsInfo({title, subtitle, image}){
    return(
        <div className="tools-info-container">
            <img src={image} alt=''/>
            <PostInfo title={title} subtitle={subtitle}/>
        </div>
    )
}

export function ToolsContent({purpose, for_whom}){
    return(
        <div className="tools-content-container">
            <ToolsSection title="Для кого" subtitle={for_whom}/>
            <ToolsSection title="Зачем" subtitle={purpose}/>
        </div>
    )
}

export function ToolsSection({title, content}){
    return(
      <div className="tools-section-container">
          <div className="tools-section-title">{title}</div>
          <div className="tools-section-content">{title}</div>
      </div>
    );
}

export function PostContent({content}){
    return(
        <div className="post-content-container">
            <p className="post-contetnt-content">{content}</p>
        </div>
    )
}

export function SectionPostContent({title, content, id, button, button_title, photo}){
    return(
        <>
            <div className="post-content-container" id={id}>
                <div className="post-content-title-container">
                    <div className="post-content-title">
                        {title}
                    </div>
                    {photo && <PhotoElementSection image={photo}/> }
                </div>
                <p className="post-contetnt-content">{content}</p>

            </div>
            {button && <div className="post-content-button" onClick={() => window.open(button)}>
                <img className="info-field-image" src='/src/images/icons/folder.svg' alt=''/>
                {button_title}
            </div>}
        </>

    )
}


export function PopupElement({desription}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="popup-element" onClick={handleShow}>
                <img className="popup-element-info" src="/src/images/icons/info.png" alt=""/>
            </div>
            <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName="popup-element-content">
                <Modal.Header closeButton>
                    <Modal.Title className="popup-element-title">Основная задача должности</Modal.Title>
                </Modal.Header>
                <Modal.Body className="popup-element-body">
                    {desription}
                </Modal.Body>
            </Modal>
        </>
    );
}

export function ProfileHeader({user}) {
    return (
        <div className="header-container">
        <div className="profile-title-container">{user.full_name}</div>
            <div className="subtitle-block-container">
                <div className="profile-subtitle-container">
                    {`${user.structure.structure_name} | ${user.role.role_name}${user.team.length > 0 ? ` | ${user.team[0].team_name}` : ''}`}
                </div>
                <PopupElement desription={user.role.role_description}/>
            </div>
        </div>
    );
}

export function ProfileInfo({user}){
    const [content, setContent] = useState([
        {title: 'День рождения', content: (new Date(user.birth_date)).toLocaleString("ru-RU", { year: 'numeric',
                month: 'numeric',
                day: 'numeric',}), link: null},
        {title: 'Работает с', content: (new Date(user.starts_work )).toLocaleString("ru-RU", { year: 'numeric',
                month: 'numeric',
                day: 'numeric',}), link: null},
        {title: 'Увлечения', content: user.hobbies, link: null},
        {title: 'Профессиональный опыт', content: user.experience, link: null},
        {title: 'Как работает?', content: user.is_remote ? "Удаленно" : "В офисе", link: null},
        {title: 'Город', content: user.city, link: null},
    ]);
    return(
        <>
            <InfoDropdownField image='/src/images/icons/gray_paragraph.png' title="Обо мне" content={content}/>
            <InfoField image='/src/images/icons/gray_mail.png' title={user.user_email}/>
            <InfoField image='/src/images/icons/gray_phone.png' title={user.phone ? user.phone : 'Нет информации'}/>
            <InfoField image='/src/images/icons/gray_tg.png' title={`@${user.username}`}/>
        </>
    )
}

export function InfoDropdownSection({title, content, link}){
    return (
        <a href={`#${link}`} className="info-dropdown-anchor">
            <div className="info-dropdown-section">
                <div className="info-dropdown-header">{title}</div>
                <div className="info-dropdown-text">{content ? content : ''}</div>
            </div>
        </a>

    )
}

function ContextAwareToggle({children, eventKey, callback}) {
    const {activeEventKey} = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className="info-dropdown-header">
            <button type="button" className="info-dropdown-button" onClick={decoratedOnClick}>
                {children}
            </button>
            <img src={isCurrentEventKey ? '/src/images/icons/arrow-up.svg' : '/src/images/icons/arrow-down.svg'} alt=''/>
        </div>

    );
}

export function InfoDropdownField({image, content, title})
{
    return (
        <div className="info-field-container">
            <img className="info-field-image" src={image} alt=''/>
            <Accordion className="info-section info-accordion">
                <Card className="info-section info-card">
                    <Card.Header className="info-section info-card-header">
                        <ContextAwareToggle eventKey="0">{title}</ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="info-section info-card-body">
                            <div className="info-dropdown-container">
                                {content && content.map(obj => <InfoDropdownSection title={obj.title} content={obj.content} link={obj.link}/>)}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}


export function InfoField({image, title}) {
    return (
        <div className="info-field-container">
            <img className="info-field-image" src={image} alt=''/>
            <div className="info-field-title">{title}</div>
        </div>
    );
}


export function InputElement({title, type, value, name, maxLength}) {
    const [values, setValues] = useState(value);

    function changeHandle(e){
        setValues(e.target.value);
    }
    return (
        <div className="form-input-container">
            <div className="form-input-container-label">{title}</div>
            { type !== "text" ?
                <input className={`form-input-container-input${type === 'submit' ? '-submit' : ''}`}
                       type={type} value={values} name={name} onChange={changeHandle} maxLength={maxLength}/> :
                <textarea className={`form-input-container-input${type === 'submit' ? '-submit' : ''}`}
                          name={name} onChange={changeHandle}>{values}</textarea>
            }

        </div>
    )
}

export function EditProfileForm({fields}) {
    return (
        <div className="edit-profile-form">
            {fields && fields.map(field => <InputElement
                  title={field.title}
                  type={field.type}
                  value={field.value}
                  name={field.name}
                  maxLength={field.maxlength}/>)}
      </div>
    );
}

export function SearchBar () {
    const [searchText, setSearchText] = useState('');
    const [searchList, setSearchList] = useState([]);
    const [resultPreview, setResultPreview] = useState([]);
    const [searchHandler, setSearchHandler] = useState(null);
    const options = {
        includeScore: true,
        // Search in `title` and in `description` array
        keys: ['title', 'description']
    };

    useEffect(() => {
        setSearchList([...searchList,
            {title: "HandBook", description: "О компании и работе в ней", path: "/helpful/handbook"},
            {title: "Об агентстве", description: "Коротко о нас", path: "/helpful/handbook/about"},
            {title: "Структура", description: "Люди в компании", path: "/helpful/handbook/structure"},
            {title: "Ценности", description: "Как работать Эффективно", path: "/helpful/handbook/worth"},
            {title: "Формат работы", description: "Как мы работаем и где", path: "/helpful/handbook/work_format"},
            {title: "Отдых", description: "Как мы отдыхаем", path: "/helpful/handbook/vacation"},
            {title: "Карьерный рост", description: "Вверх и ввысь!", path: "/helpful/handbook/growth"},
            {title: "Реферальная программа", description: "Посоветуй нас друзьям", path: "/helpful/handbook/referral"},
            {title: "Сотрудники", description: "Профили и структура", path: "/helpful/employers"},
            {title: "Полнезные материалы", description: "Полнезные материалы", path: "/helpful/knowledge"},
            {title: "Инструменты", description: "Необходимое для работы", path: "/helpful/tools"},
            {title: "Инструменты", description: "Что мы используем в работе", path: "/helpful/tools"},
            {title: "Актуальное", description: "Новости и ивенты", path: "/actual"}]);
        // FOR USERS
        authFetch('/api/get/Users').then(res => res.json()).then(data => data.map((item) =>
        {setSearchList([...searchList, {title: item.full_name, description: item.about, path: `/Users?id=${item.user_id}`}]);}));
        // FOR TOOLS
        authFetch('/api/get/Tools').then(res => res.json()).then(data => data.map((item) =>
        {setSearchList([...searchList, {title: item.tool_name, description: item.tool_whom, path: `/Tools?id=${item.tool_id}`}]);}));
        // FOR NEWS
        authFetch('/api/get/News').then(res => res.json()).then(data => data.map((item) =>
        {setSearchList([...searchList, {title: item.news_title, description: item.news_type, path: `/News?id=${item.news_id}`}]);}));
        // FOR LAWFINANCE
        authFetch('/api/get/LawFinance').then(res => res.json()).then(data => data.map((item) =>
        {setSearchList([...searchList, {title: item.law_title, description: "", path: `/LawFinance?id=${item.law_id}`}]);}));
    }, []);

    useEffect(() => {
        setSearchHandler(new Fuse(searchList, options));
    }, [options, searchList]);

    useEffect(() => {
        resultPreview.sort(function(a,b) {
            return b.score - a.score
        });
    }, [resultPreview]);


    return(
        <div className="search-bar">
            <input className="search-bar-input" value={searchText} onChange={event => {
                setResultPreview(searchHandler.search(event.target.value));
                setSearchText(event.target.value);
            }}/>
            <button className="search-bar-button" onClick={event => window.location.assign(`/search?q=${searchText}`)} type="submit"></button>
            <div className="search-bar-preview">
                {resultPreview && resultPreview.map(obj => <SearchPreviewElement 
                    title={obj.item.title} 
                    path={obj.item.path} 
                    description={obj.item.description}/>)}
            </div>
        </div>
    )
}

function SearchPreviewElement({path, title, description}){
    return (
        <div className="search-bar-preview-elem" onClick={e => window.location.assign(path)}>
            <div className="search-bar-preview-elem-title">{title}</div>
            <div className="search-bar-preview-elem-description">{description}</div>
        </div>
    )
}

export function SearchElement({path, title, description}){
    return (
        <div className="search-bar-elem" onClick={e => window.location.assign(path)}>
            <div className="search-bar-elem-title">{title}</div>
            <div className="search-bar-elem-description">{description}</div>
        </div>
    )
}

export function QuestionStart(){
    const [user, setUser] = useState(null);
    const tg = window.Telegram.WebApp;
    useEffect(()=>{
        authFetch(`api/get/Users?telegram_id=${tg.initDataUnsafe.user.id}`).then(res => res.json()).then(data => {
            setUser(data.result[0]);
            sessionStorage.setItem('user_id', data.result[0].user_id);
        });
        tg.BackButton.show();
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Начать");
        tg.MainButton.isVisible = true;
        tg.BackButton.hide();
        tg.MainButton.onClick(() => {
            authFetch('/api/post/Answers', {method: 'POST',
            body: {
                user_id: user.user_id,
                survey_id: 4,
            }}).then(res => res.json()).then(data => sessionStorage.setItem("answers_id", data.objects[0].answers_id));
            window.location.assign('/onboarding/handbook/1');});
    }, [])
    return(
        <div className="story-question-container">
            <div className="story-image-container">
                <div className="story-header-container">
                    <div className="story-image-title">тест</div>
                </div>
            </div>
            <div className="story-title-container">
                <div className="story-title auto-height">Привет!</div>
                <div className="story-subtitle">Сейчас ты узнаешь все, что надо знать о компании Whiteleads. Блоки с теорией будут сменяться вопросами, а в конце ты увидишь оценку прохождения. Иногда ответ будет не на поверхности и нужно будет подумать.</div>
                <img className="question-start" src="/src/images/questions/start.png" alt=""/>
            </div>
        </div>)
}

export function Quiz(){
    const params = useParams();
    const [quest, setQuest] = useState(null);
    useEffect(() => {
        authFetch(`/api/get/Question?block=${params.block}&survey_id=4`).then(res => res.json()).then(data => setQuest(data.result[params.id-1]));
    }, []);
}

export function Question({quest}){
    const tg = window.Telegram.WebApp;
    const [correct, setCorrect] = useState(null);
    const [answer, setAnswer] = useState(null);
    useEffect(()=>{
        if(quest !== null){
            quest.options.map((option) => option.is_correct && setCorrect(option));
        }
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Проверить");
        tg.MainButton.isVisible = false;
        tg.MainButton.onClick(() => handleSubmit);
        tg.BackButton.hide();
        return () => {
            tg.MainButton.offClick(handleSubmit);
        }
    }, [quest])
    function handleSubmit(){
        if(answer !== null){
            let is_correct = Number(answer) === correct.option_id;
            authFetch('/api/post/Answer', {
                method: "POST",
                body: {
                    answer_content: answer,
                    is_correct: is_correct,
                    answers_id: sessionStorage.getItem('answers_id'),
                    question_id: quest.question_id}}).then(res => res.json()).then(() => {
                        window.location.assign('/onboarding/handbook/1');
            })
        }

    }
    function handleChange(e){
        if(e.target !== null){
            setAnswer(e.target.value);
            tg.MainButton.isVisible = true;
        }
        else{
            setAnswer(null);
            tg.MainButton.isVisible = false;
        }
    }
    return(
        <>
            {quest && <div className="story-question-container">
            <div className="story-image-container">
                <div className="story-header-container">
                    <div className="story-image-title">вопрос</div>
                </div>
            </div>
            <div className="story-title-container">
                <div className="story-title auto-height">{quest.question_name}</div>
                <div className="story-subtitle">{quest.question_title}</div>
                <img className="question-start" src="/src/images/questions/start.png" alt=""/>
                <div className="story-options-container">
                    <form className="story-options-form" onChange={handleChange}>
                        {quest.options && quest.options.map((option) => (<input className="answer-option" name="answer_content" value={option.option_id}>{option.option_content}</input>))}
                    </form>
                </div>
            </div>
            </div>}
        </>
    )
}

export function ResultQuestion({}){}

export function ResultOption(){}

export function CorrectOption({}) {}

export function WrongOption({}){}