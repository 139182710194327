import '../css/index.min.css'
import {useEffect, useState} from "react";
import {ScrollRestoration, useSearchParams} from "react-router-dom";
import {TagMenu, Title} from "./Elements";
import authFetch from "./auth/authFetch";
import {init_tg} from "./init_tg";


export function PageTeam(){
    const [params, setParams] = useSearchParams();
    const [title, setTitle] = useState(null);
    const [listOfTags, setListOfTags] = useState(null);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        console.log(params.get("id"));
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '').replace(/#.*/, '');
        (path !== '/Users' && path !== '/Users/edit' && path !== '/Users/achievements') && sessionStorage.setItem("ref_employee", `${path}`);
        init_tg(tg, sessionStorage.getItem("ref_employee"), sessionStorage.getItem("ref_employee"));
        authFetch(`/api/get/Team?id=${Number(params.get("id"))}`).then(response =>
            response.json()).then(data => setTitle(data.result[0].team_name));
        setListOfTags([
            {title: 'Все', isActive: true, type: "Team", queryPath: "get/Users", filter: {schematic: true, is_working: true, team_id: Number(params.get("id"))}, isElement: true},
        ]);
    }, [params]);
    useEffect(()=>{
        document.title = title;
    }, [title]);
    return(
        <>
            <div className="main-block">
                <Title title={title}/>
                {listOfTags && <TagMenu listOfTags={listOfTags}/>}
            </div>
            <ScrollRestoration/>
        </>
    );
}