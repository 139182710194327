import '../css/index.min.css'
import {useEffect, useState} from "react";
import {ScrollRestoration, useSearchParams} from "react-router-dom";
import {TagMenu, Title} from "./Elements";
import authFetch from "./auth/authFetch";
import {init_tg} from "./init_tg";


export function PageStructure(){
    const [params, setParams] = useSearchParams();
    const [title, setTitle] = useState(null);
    const defaultTags = [
        {title: 'Все', isActive: true, type: "All", queryPath: "getUsersStructure", filter: {schematic: true, id: Number(params.get("id"))}, isElement: true},
    ];
    const [listOfTags, setListOfTags] = useState(defaultTags);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '').replace(/#.*/, '');
        (path !== '/Users' && path !== '/Users/edit' && path !== '/Users/achievements') && sessionStorage.setItem("ref_employee", `${path}`);
        init_tg(tg, sessionStorage.getItem("ref_employee"), sessionStorage.getItem("ref_employee"));
        authFetch(`/api/get/Structure?id=${Number(params.get("id"))}`).then(response =>
            response.json()).then(data => setTitle(data.result[0].structure_name));
        authFetch(`/api/get/Team?team_structure=${Number(params.get("id"))}`).then(response =>
            response.json()).then(data => data.result.map((obj) => {
                setListOfTags(item =>[...item, {title: obj.team_name,
                        isActive: Number(params.get("structure")) === obj.team_id,
                        type: obj.team_name,
                        queryPath: "get/Users",
                        filter: {team_id: obj.team_id, schematic: true, is_working: true},
                    isElement: true}]);
                if(Number(params.get("structure"))>data.result.length){
                    listOfTags[0].isActive = true;
                }
            }));
    }, []);
    useEffect(()=>{
        document.title = title;
    }, [title]);
    return(
        <>
            <div className="main-block">
                <Title title={title}/>
                {listOfTags && <TagMenu listOfTags={listOfTags}/>}
            </div>
            <ScrollRestoration/>
        </>
    );
}