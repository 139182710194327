import '../css/tools.min.css';
import {PhotoElement, ProfileHeader, ProfileInfo} from "./Elements";
import {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import authFetch from "./auth/authFetch";


function Profile({user}){
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '');
        const params = new URL(document.referrer).search;
        (path !== '/Users/edit' && path !== '/Users/achievements') && sessionStorage.setItem("ref", `${path}${params}#${user.user_id}`);
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.BackButton.onClick((() => window.location.assign(sessionStorage.getItem("ref"))));
    }, []);
    useEffect(() => {
        document.title = user.full_name;
        try{
            if(Number(tg.initDataUnsafe.user.id) !== Number(user.telegram_id)){
                tg.MainButton.setText("Написать в Telegram");
                tg.MainButton.onClick((() => window.location.assign(`https://t.me/${user.username}`)));
            }
            else{
                tg.MainButton.setText("Редактировать профиль");
                tg.MainButton.onClick((() => window.location.assign("/Users/edit")));
            }
        }catch(e){
            console.log(e);
        }
    }, [user]);

    return(
        <>
            <ProfileHeader user={user}/>
            {user.achievements.length > 0 ? <PhotoElement image={user.profile_photo} button={`/Users/achievements?id=${user.user_id}`}/>:
                <PhotoElement image={user.profile_photo}/>}
            <ProfileInfo user={user}/>
        </>
    );
}


export function PageProfile(){
    const [user, setUser] = useState(null);
    const [params, setParams] = useSearchParams();

    useEffect(()=>{
        document.title = 'Пользователь';
        authFetch(`/api/get/Users?user_id=${params.get('id')}`).then(res=>
            res.json()).then(data => {
            setUser(data.result[0]);
        });
    }, []);


    return(
        <div className="main-block">
            { user && <Profile user={user}/> }
        </div>
    )
}