import {PostInfo, InfoDropdownField, InfoDropdownSection} from "./Elements";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import authFetch from "./auth/authFetch";

function Tool({tool}){
    const [content, setContent] = useState([
        {title: '', content: ''}
    ]);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        setContent([
            {title: '', content: tool.tool_description}
        ])
        // eslint-disable-next-line no-unused-expressions
        tool.subtool && tool.subtool.map((obj) => setContent(item => [...item,
            {title: obj.subtool_title,
                content: obj.subtool_description}]))
        document.title = tool.tool_name;
        try{
            tg.BackButton.show();
            tg.MainButton.isVisible = true;
            tg.MainButton.color = '#007AFF';
            tg.MainButton.textColor = '#ffffff';
            tg.MainButton.setText("Открыть");
            tg.MainButton.onClick((() => window.open(tool.tool_link)));
            tg.BackButton.onClick((() => window.location.assign(`/helpful/tools#${tool.tool_id}`)));
        }catch (e){ console.log(e) }
    }, [tool]);

    return(
        <>
            <div className="tool-title-container">
                <img className="tool-title-img" src={tool.tool_photo} alt=''/>
                <PostInfo title={tool.tool_name} subtitle={tool.tool_whom ? tool.tool_whom : 'Используют все'}/>
            </div>
            <div className="tool-content-container">
                <InfoDropdownSection title="Зачем" content={tool.tool_purpose}/>
            </div>
            <InfoDropdownField image='/src/images/icons/gray_paragraph.png' title={tool.tool_description_title ?
                tool.tool_description_title : 'Описание'} content={content}/>
        </>
    );
}


export function PageTools() {
    const [tool, setTool] = useState(null);
    const [params, setParams] = useSearchParams();
    useEffect(()=>{
        document.title = 'Новость';
        authFetch(`/api/get/Tools?id=${params.get('id')}`).then(res=>
            res.json()).then(data => setTool(data.result[0]));
        try{}catch(e){}
    },[]);
    return(
        <div className="main-block">
            {tool && <Tool tool={tool}/>}
        </div>
    );
}