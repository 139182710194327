import '../css/index.min.css'
import {useEffect, useState} from "react";
import {TagMenu, Title} from "./Elements";

const defaultTags = [
    {title: 'Все', isActive: true, type: "All", queryPath: "get/LawFinance", filter: {schematic: true}, isElement: true },
];

export function PageMenuKnowlegde(){
    const [listOfTags, setListOfTags] = useState(defaultTags);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = 'База знаний';
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.BackButton.onClick((() => window.location.assign('/helpful')));
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick(() => window.location.assign('/helpful'));
    }, []);
    return(
        <div className="main-block">
            <Title title="Полезные материалы"/>
            <TagMenu listOfTags={listOfTags}/>
        </div>
    );
}