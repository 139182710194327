import '../css/index.min.css'
import {useEffect, useState} from "react";
import {TagMenu, Title} from "./Elements";
import authFetch from "./auth/authFetch";

const defaultTags = [
    {title: 'Все', isActive: true, type: "All", queryPath: "get/Tools", filter: {schematic: true}, isElement: true},
];

export function PageMenuTools(){
    const [listOfTags, setListOfTags] = useState(defaultTags);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = 'Инструменты';
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '');
        (path !== '/Tools') && sessionStorage.setItem("ref", `${path}`);
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Назад");
        tg.BackButton.onClick((() => window.location.assign(sessionStorage.getItem("ref"))));
        tg.MainButton.onClick(() => window.location.assign(sessionStorage.getItem("ref")));
        authFetch("/api/get/Structure?not_tool=").then(response =>
            response.json()).then(data => data.result.map((obj) => {
            setListOfTags(item =>[...item, {title: obj.structure_name,
                isActive: false,
                type: obj.structure_name,
                queryPath: "get/Tools",
                filter: {structure_id: obj.structure_id, schematic: true}, isElement: true}]);
        }));
    }, []);
    return(
        <div className="main-block">
            <Title title="Инструменты"/>
            <TagMenu listOfTags={listOfTags}/>
        </div>
    );
}