import '../css/index.min.css'
import {useEffect, useState} from "react";
import {ScrollRestoration} from "react-router-dom";
import {TagMenu, Title} from "./Elements";
import {init_tg} from "./init_tg";


export function PageMenuEmployee(){
    const defaultTags = [
        {title: 'По отделам', isActive: true, type: "All", queryPath: "employee/menu", filter: {schematic: true}, isElement: false},
        {title: 'Все', isActive: false, type: "All", queryPath: "getUsersAll", filter: {schematic: true}, isElement: true},
    ];
    const [listOfTags, setListOfTags] = useState(defaultTags);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '').replace(/#.*/, '');
        (path !== '/Users'  && path !== '/helpful/employers' && path !== '/Team' && path!=='/Structure') && sessionStorage.setItem("ref_handbook", `${path}`);
        document.title = 'Сотрудники';
        init_tg(tg, sessionStorage.getItem("ref_handbook"), sessionStorage.getItem("ref_handbook"));
        /*authFetch("/api/get/Structure").then(response =>
            response.json()).then(data => data.result.map((obj) => {
                setListOfTags(item =>[...item, {title: obj.structure_name,
                        isActive: Number(params.get("structure")) === obj.structure_id,
                        type: obj.structure_name,
                        queryPath: "get/Users",
                        filter: {structure_id: obj.structure_id, schematic: true, is_working: true}}]);
                if(Number(params.get("structure"))>data.result.length){
                    listOfTags[0].isActive = true;
                }
            }));*/
    }, []);
    return(
        <>
            <div className="main-block">
                <Title title="Сотрудники"/>
                {listOfTags.length >= 2 && <TagMenu listOfTags={listOfTags}/>}
            </div>
            <ScrollRestoration/>
        </>
    );
}