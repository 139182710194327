import '../css/elements.min.css';
import {useEffect, useState} from "react";
import {TagMenu, Title} from "./Elements";


export function PageMenuActual(){
    const [listOfTags, setListOfTags] = useState([
        {title: 'Все', isActive: true, type: "All", queryPath: "get/News", filter: {schematic: true}, isElement: true},
        {title: 'События', isActive: false, type: "Событие", queryPath: "get/News", filter: {news_type: "Событие", schematic: true}, isElement: true},
        {title: 'Новости', isActive: false, type: "Новости", queryPath: "get/News", filter: {news_type: "Новость", schematic: true}, isElement: true},
        {title: 'Объявления', isActive: false, type: "Обьявления", queryPath: "get/News", filter: {news_type: "Объявление", schematic: true}, isElement: true},
        {title: 'Опросы', isActive: false, type: "Опрос", queryPath: "get/News", filter: {news_type: "Опрос", schematic: true}, isElement: true},
    ]);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick(() => window.location.assign('/'));
        tg.BackButton.onClick((() => window.location.assign('/')));
        document.title = 'Актуальное';
    }, []);
    return(
        <div className="main-block">
            <Title title="Актуальное"/>
            <TagMenu listOfTags={listOfTags}/>
        </div>
    )
}